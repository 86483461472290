import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
// 路由配置列表
const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  // 面试测评
  {
    path: '/scanInterviewEntry',
    name: 'scanInterviewEntry',
    component: () => import('@/views/scanInterviewEntry'),
    // 不需要登录的时候，nologin设置为true
    meta: { nologin: true }
  },
  // 绑定手机和openid
  {
    path: '/bindWXOpenid',
    name: 'bindWXOpenid',
    component: () => import('@views/bindWXOpenid/index.vue'),
    meta: { nologin: true }
  },
  // 扫码签到
  {
    path: '/scanSignIn',
    name: 'scanSignIn',
    component: () => import('@/views/scanSignIn/index.vue'),
    // 如果需要登录后重定向，设置为true
    meta: {
      // redirect: true
    }
  },
  // 登录页
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/index.vue')
  },
  // 工作台首页路由
  {
    path: '/layout',
    name: 'layout',
    component: () => import('@/views/layout'),
    children: [
      {
        path: '/home',
        name: 'home',
        component: () => import('@/views/home'),
        meta: { keepAlive: true }
      }, // 查询界面
      {
        path: '/query',
        name: 'query',
        component: () => import('@/views/query/index.vue'),
        meta: { keepAlive: true }
      },
      // 申请界面
      {
        path: '/apply',
        name: 'apply',
        component: () => import('@/views/apply/index.vue'),
        meta: { keepAlive: true }
      },
      // 员工路由
      {
        path: '/staff',
        name: 'staff',
        component: () => import('@/views/staff'),
        meta: { keepAlive: true }
      },
      // 统计路由
      {
        path: '/statistics',
        name: 'statistics',
        component: () => import('@/views/statistics'),
        meta: { keepAlive: true }
      },
      // 个人中心路由
      {
        path: '/personCen',
        name: 'personCen',
        component: () => import('@/views/personCen'),
        meta: { keepAlive: true }
      },
      // 保留底部的跳转
      {
        path: '/jump',
        name: 'jump',
        component: () => import('@/views/jump/index.vue'),
        meta: { keepAlive: false }
      },
      // 报表
      {
        path: '/report',
        name: 'report',
        component: () => import('@/views/report/index.vue'),
        meta: { keepAlive: false }
      },
      // 报表条件
      {
        path: '/previewRp',
        name: 'previewRp',
        component: () => import('@/views/report/previewRp.vue'),
        meta: { keepAlive: false }
      }
    ]
  },
  // 保留底部的跳转-报表
  {
    path: '/jumpReport',
    name: 'jumpReport',
    component: () => import('@/views/jump/jumpReport.vue'),
    meta: { keepAlive: false }
  },
  // 待面试人员列表页面
  {
    path: '/toInterview',
    name: 'toInterview',
    component: () => import('@/views/toInterview')
  },
  // 面试测评--结果详情
  {
    path: '/interviewResults',
    name: 'interviewResults',
    component: () => import('@/views/toInterview/interviewCPresults.vue')
  },
  // 工资签收 - 密码
  {
    path: '/salarySiginPassword',
    name: 'salarySiginPassword',
    component: () => import('@/views/salarySigin/password.vue')
  },
  // 工资签收 - 短信验证
  {
    path: '/smsVerification',
    name: 'smsVerification',
    component: () => import('@/views/salarySigin/smsVerification.vue')
  },
  // 工资签收 - 列表
  {
    path: '/salarySiginList',
    name: 'salarySiginList',
    component: () => import('@/views/salarySigin/list.vue')
  },
  // 工资签收 - 每月
  {
    path: '/salaryMonth',
    name: 'salaryMonth',
    component: () => import('@/views/salarySigin/month.vue')
  },
  // 工资签收 - 详情
  {
    path: '/salaryDetails',
    name: 'salaryDetails',
    component: () => import('@/views/salarySigin/details.vue')
  },
  // 我的证件 - 列表页
  {
    path: '/MyIdCard',
    name: 'MyIdCard',
    component: () => import('@/views/MyIdCard/index.vue')
  },
  // 我的证件 - 添加页
  {
    path: '/addMyIdCard',
    name: 'addMyIdCard',
    component: () => import('@/views/MyIdCard/add.vue')
  },
  // 我的证件 - 详情页
  {
    path: '/myIdCardDetails',
    name: 'myIdCardDetails',
    component: () => import('@/views/MyIdCard/details.vue')
  },
  // 内推二维码
  {
    path: '/reqrcode',
    name: 'reqrcode',
    component: () => import('@/views/reqrcode')
  },
  // 依时利消费二维码
  {
    path: '/EastRiverQrcode',
    name: 'EastRiverQrcode',
    component: () => import('@/views/EastRiverQrcode')
  },
  // 待面试确认
  {
    path: '/confirmInterview',
    name: 'confirmInterview',
    component: () => import('@/views/confirmInterview')
  },
  // 待面试确认 - 个人信息
  {
    path: '/confirmInterviewInfo',
    name: 'confirmInterviewInfo',
    component: () => import('@/views/confirmInterviewInfo'),
    // 如果需要登录后重定向，设置为true
    meta: { redirect: true }
  },
  // 待转正确认
  {
    path: '/turningPositive',
    name: 'turningPositive',
    component: () => import('@/views/turningPositive'),
    // 如果需要登录后重定向，设置为true
    meta: { redirect: true }
  },
  // 待转正确认 - 个人信息
  {
    path: '/turningPositiveInfo',
    name: 'turningPositiveInfo',
    component: () => import('@/views/turningPositiveInfo')
  },
  // KPI待评价记录
  {
    path: '/jxDataSocre',
    name: 'jxDataSocre',
    component: () => import('@/views/jxDataSocre/index.vue'),
    // 如果需要登录后重定向，设置为true
    meta: { redirect: true }
  },
  // KPI待评价记录详情
  {
    path: '/jxDataSocreDetail',
    name: 'jxDataSocreDetail',
    component: () => import('@/views/jxDataSocre/detai.vue')
  },
  // 放弃入职
  {
    path: '/giveUpEntry',
    name: 'giveUpEntry',
    component: () => import('@/views/giveUpEntry')
  },
  // 面试者个人基本信息路由
  {
    path: '/viewInfo',
    name: 'viewInfo',
    component: () => import('@/views/viewInfo')
  },
  // 待入职人员列表页面
  {
    path: '/toInduction',
    name: 'toInduction',
    component: () => import('@/views/toInduction')
  },
  // 待入职者个人基本信息路由
  {
    path: '/inductionInfo',
    name: 'inductionInfo',
    component: () => import('@/views/inductionInfo')
  },
  // 面试淘汰页面路由
  {
    path: '/viewElimination',
    name: 'viewElimination',
    component: () => import('@/views/interviewEliminaton.vue')
  },
  // 简历筛选页面路由
  {
    path: '/resumeSel',
    name: 'resumeSel',
    component: () => import('@/views/resumeSelection.vue')
  },
  // 课程评价提交成功页
  {
    path: '/kcpjtjPage',
    name: 'kcpjtjPage',
    component: () => import('@/views/kcpjtjPage/index.vue')
  },
  // 课程评价提交成功页
  {
    path: '/kcpjtjResult',
    name: 'kcpjtjResult',
    component: () => import('@/views/kcpjtjResult/index.vue')
  },
  // 安排人员列表页面
  {
    path: '/arrangeInterview',
    name: 'arrangeInterview',
    component: () => import('@/views/arrangeInterview')
  },
  // 办理入职页面路由
  {
    path: '/applyEntry',
    name: 'applyEntry',
    component: () => import('@/views/applyEntry')
  },
  // 扫码入职
  {
    path: '/saoEntry',
    name: 'saoEntry',
    component: () => import('@/views/saoEntry')
  },
  // 面试通知
  {
    path: '/interviewNotice',
    name: 'interviewNotice',
    component: () => import('@/views/interviewNotice')
  },
  // 入职记录
  {
    path: '/entryRecord',
    name: 'entryRecord',
    component: () => import('@/views/entryRecord')
  },
  // 确认入职
  {
    path: '/confirmEntry',
    name: 'confirmEntry',
    component: () => import('@/views/confirmEntry')
  },
  // 选择岗位树
  {
    path: '/selStation',
    name: 'selStation',
    component: () => import('@/views/selStation')
  },
  // 测试控件
  {
    path: '/test',
    name: 'test',
    component: () => import('@/views/test')
  },
  // 发送offer
  {
    path: '/sendOffer',
    name: 'sendOffer',
    component: () => import('@/views/sendOffer')
  },
  // offer通知
  {
    path: '/offerNotice',
    name: 'offerNotice',
    component: () => import('@/views/offerNotice')
  },
  // 编辑入职信息
  {
    path: '/editOnboarding',
    name: 'editOnboarding',
    component: () => import('@/views/editOnboarding')
  },
  // 面试评价
  {
    path: '/viewEvaluate',
    name: 'viewEvaluate',
    component: () => import('@/views/viewEvaluate')
  },
  // 待考试
  {
    path: '/toExamined',
    name: 'toExamined',
    component: () => import('@/views/toExamined')
  },
  // 考试信息

  {
    path: '/examinInfo',
    name: 'examinInfo',
    component: () => import('@/views/examinInfo')
  },
  // 待学习
  {
    path: '/toLearn',
    name: 'toLearn',
    component: () => import('@/views/toLearn')
  },
  // 学习课程
  {
    path: '/learningCourses',
    name: 'learningCourses',
    component: () => import('@/views/learningCourses')
  },
  // 考试界面
  {
    path: '/examination',
    name: 'examination',
    component: () => import('@/views/examination')
  },
  // 签到培训
  {
    path: '/signinTraining',
    name: 'signinTraining',
    component: () => import('@/views/signinTraining')
  },
  // 考试提交后的一个页面
  {
    path: '/submitPage',
    name: 'submitPage',
    component: () => import('@/views/submitPage')
  },
  // 签到详情
  {
    path: '/registrationDetails',
    name: 'registrationDetails',
    component: () => import('@/views/registrationDetails')
  },

  // 答案解析界面
  {
    path: '/checkOutAnswer',
    name: 'checkOutAnswer',
    component: () => import('@/views/checkOutAnswer')
  },

  // 培训报名
  {
    path: '/trainingRegister',
    name: 'trainingRegister',
    component: () => import('@/views/trainingRegister')
  },
  // 培训报名详情
  {
    path: '/signUpDetails',
    name: 'signUpDetails',
    component: () => import('@/views/signUpDetails')
  },
  // 培训报名结果
  {
    path: '/signUpSuccessfully',
    name: 'signUpSuccessfully',
    component: () => import('@/views/signUpSuccessfully')
  },
  // 待评价课程
  {
    path: '/toEvaluated',
    name: 'toEvaluated',
    component: () => import('@/views/toEvaluated')
  },
  // 培训记录备份
  {
    path: '/trainingRecord',
    name: 'trainingRecord',
    component: () => import('@/views/trainingRecord')
  },
  // 培训记录详情备份
  {
    path: '/trainingDetails',
    name: 'trainingDetails',
    component: () => import('@/views/trainingDetails')
  },
  // 培训记录
  {
    path: '/trainRecords',
    name: 'trainRecords',
    component: () => import('@/views/trainRecords/index.vue')
  },
  // 培训记录
  {
    path: '/trainRecordsDetails',
    name: 'trainRecordsDetails',
    component: () => import('@/views/trainRecordsDetails/index.vue')
  },
  // 待评价课程问卷
  {
    path: '/questionnaire',
    name: 'questionnaire',
    component: () => import('@/views/questionnaire')
  },
  // 培训协议
  {
    path: '/trainingAgreement',
    name: 'trainingAgreement',
    component: () => import('@/views/trainingAgreement')
  },

  //考试记录
  {
    path: '/examRecord',
    name: 'examRecord',
    component: () => import('@/views/examRecord')
  },
  // 考试记录已经审核后的详情
  {
    path: '/reviewedInfo',
    name: 'reviewedInfo',
    component: () => import('@/views/reviewedInfo')
  },

  // 绩效申诉
  {
    path: '/jxPerformanceAppeals',
    name: 'jxPerformanceAppeals',
    component: () => import('@/views/jxPerformanceAppeals'),
    // 如果需要登录后重定向，设置为true
    meta: { redirect: true }
  },

  // 绩效申诉--申诉详情
  {
    path: '/jxAppealsDetail',
    name: 'jxAppealsDetail',
    component: () => import('@/views/jxPerformanceAppeals/appeals.vue')
  },

  // 添加绩效申诉
  {
    path: '/jxAddPerformance',
    name: 'jxAddPerformance',
    component: () => import('@/views/jxAddPerformance')
  },
  // 我的考核表
  {
    path: '/jxMyAssessment',
    name: 'jxMyAssessment',
    component: () => import('@/views/jxMyAssessment')
  },
  // 我的考核表详情
  {
    path: '/jxMyAssessmentDetails',
    name: 'jxMyAssessmentDetails',
    component: () => import('@/views/jxMyAssessment/details.vue')
  },
  // 评价记录
  {
    path: '/jxEvaluationRecord',
    name: 'jxEvaluationRecord',
    component: () => import('@/views/jxEvaluationRecord')
  },

  // 评价记录详情
  {
    path: '/jxEvaluationRecordDetails',
    name: 'jxEvaluationRecordDetails',
    component: () => import('@/views/jxEvaluationRecord/details.vue')
  },

  // 评价人审核
  {
    path: '/jxAppraiserReview',
    name: 'jxAppraiserReview',
    component: () => import('@/views/jxAppraiserReview')
  },
  // 绩效-待评价记录
  {
    path: '/jxWhenMyAssesList',
    name: 'jxWhenMyAssesList',
    component: () => import('@/views/jxWhenMyAssesList')
  },
  // 绩效-待评价记录-详细
  {
    path: '/jxWhenMyAssesListDetail',
    name: 'jxWhenMyAssesListDetail',
    component: () => import('@/views/jxWhenMyAssesList/detail.vue')
  },
  // 外网单点登录
  {
    path: '/Asclogin',
    name: 'Asclogin',
    component: () => import('@/views/Asclogin'),
    // 不需要登录的时候，nologin设置为true
    meta: { nologin: true }
  },
  // 外网单点登录2
  {
    path: '/faceCollectionSso',
    name: 'faceCollectionSso',
    component: () => import('@/views/faceCollectionSso'),
    // 不需要登录的时候，nologin设置为true
    meta: { nologin: true }
  },
  // 外网单点登录2
  {
    path: '/commSsoJump',
    name: 'commSsoJump',
    component: () => import('@/views/commSsoJump'),
    // 不需要登录的时候，nologin设置为true
    meta: { nologin: true }
  },
  // 皇派评价界面
  {
    path: '/HPKpi',
    name: 'HPKpi',
    component: () => import('@/views/HPKpi'),
    // 不需要登录的时候，nologin设置为true
    meta: { nologin: true }
  },
  // 忘记密码
  {
    path: '/ForgetPwd',
    name: 'ForgetPwd',
    component: () => import('@/views/ForgetPwd'),
    // 不需要登录的时候，nologin设置为true
    meta: { nologin: true }
  },
  // 修改密码
  {
    path: '/changePassword',
    name: 'changePassword',
    component: () => import('@/views/changePassword')
  },

  // 我的考核指标
  {
    path: '/jxMyAssTarget',
    name: 'jxMyAssTarget',
    component: () => import('@/views/jxMyAssTarget')
  },
  // 我的考核指标--考核申请
  {
    path: '/jxMyAssTargetApply',
    name: 'jxMyAssTargetApply',
    component: () => import('@/views/jxMyAssTarget/application.vue')
  },
  // 我的考核指标--考核申请详情
  {
    path: '/jxMyAssTargetDetails',
    name: 'jxMyAssTargetDetails',
    component: () => import('@/views/jxMyAssTarget/details.vue')
  },

  // 我的考核结果
  {
    path: '/jxMyAssResults',
    name: 'jxMyAssResults',
    component: () => import('@/views/jxMyAssResults')
  },
  // 我的考核结果--列表
  {
    path: '/jxMyAssResultsList',
    name: 'jxMyAssResultsList',
    component: () => import('@/views/jxMyAssResults/application.vue')
  },
  // 我的考核结果--考核结果详情
  {
    path: '/jxMyAssResultsAppeal',
    name: 'jxMyAssResultsAppeal',
    component: () => import('@/views/jxMyAssResults/appeal.vue')
  },

  // 我的考核结果--绩效申诉
  {
    path: '/jxMyAssResultsDetails',
    name: 'jxMyAssResultsDetails',
    component: () => import('@/views/jxMyAssResults/details.vue')
  },

  // 待审批指标
  {
    path: '/jxApprovalTag',
    name: 'jxApprovalTag',
    component: () => import('@/views/jxApprovalTag'),
    // 如果需要登录后重定向，设置为true
    meta: { redirect: true }
  },
  //待审批指标--同意/不同意
  {
    path: '/jxApprovalTagApply',
    name: 'jxApprovalTagApply',
    component: () => import('@/views/jxApprovalTag/application.vue')
  },
  //待审批指标--同意/不同意--详情
  {
    path: '/jxApprovalTagDetails',
    name: 'jxApprovalTagDetails',
    component: () => import('@/views/jxApprovalTag/details.vue')
  },

  // 新闻中心
  {
    path: '/newsCenter',
    name: 'newsCenter',
    component: () => import('@/views/newsCenter')
  },
  //  新闻中心--新闻详情
  {
    path: '/newsDetails',
    name: 'newsDetails',
    component: () => import('@/views/newsCenter/details.vue')
  },
  // 消息中心
  {
    path: '/msgCenter',
    name: 'msgCenter',
    component: () => import('@/views/msgCenter')
  },
  //  消息中心--消息详情
  {
    path: '/msgDetails',
    name: 'msgDetails',
    component: () => import('@/views/msgCenter/details.vue')
  },
  // 个人中心--个人资料
  {
    path: '/personalData',
    name: 'personalData',
    component: () => import('@/views/personalData')
  },

  // 修改工资密码
  {
    path: '/changeSalaryPsw',
    name: 'changeSalaryPsw',
    component: () => import('@/views/changeSalaryPsw')
  },
  // 重置工资密码
  {
    path: '/resetSalaryPsw',
    name: 'resetSalaryPsw',
    component: () => import('@/views/resetSalaryPsw')
  },
  // 绩效面谈
  {
    path: '/jxPerformanceInterview',
    name: 'jxPerformanceInterview',
    component: () => import('@/views/jxPerformanceInterview'),
    // 如果需要登录后重定向，设置为true
    meta: { redirect: true }
  },
  // 统计数据
  {
    path: '/jxStatisticalData',
    name: 'jxStatisticalData',
    component: () => import('@/views/jxStatisticalData/index.vue')
  },
  // 统计数据-列表
  {
    path: '/jxStatisticalDataList',
    name: 'jxStatisticalDataList',
    component: () => import('@/views/jxStatisticalData/list.vue')
  },
  // 统计数据-新增
  {
    path: '/jxStatisticalDataAdd',
    name: 'jxStatisticalDataAdd',
    component: () => import('@/views/jxStatisticalData/add.vue')
  },
  // 统计数据-详情
  {
    path: '/jxStatisticalDataDetails',
    name: 'jxStatisticalDataDetails',
    component: () => import('@/views/jxStatisticalData/details.vue')
  },
  // 绩效面谈--面谈详情
  {
    path: '/jxInterviewDetails',
    name: 'jxInterviewDetails',
    component: () => import('@/views/jxPerformanceInterview/interviews.vue')
  },
  // 扫码跳  通用问卷
  {
    path: '/generalQuestionnaire',
    name: 'generalQuestionnaire',
    component: () => import('@/views/generalQuestionnaire/index.vue')
    // 如果需要登录后重定向，设置为true
    // meta: { redirect: true }
  },
  // 绩效面谈--面谈详情
  {
    path: '/questionnaireSubmitted',
    name: 'questionnaireSubmitted',
    component: () => import('@/views/generalQuestionnaire/submitted.vue')
  },
  // 微信订餐
  {
    path: '/weChatOrdering',
    name: 'weChatOrdering',
    component: () => import('@/views/weChatOrdering/index.vue')
  },
  // 简易订餐
  {
    path: '/simple',
    name: 'simple',
    component: () => import('@/views/weChatOrdering/simple.vue')
  },
  // 扫码就餐
  {
    path: '/QrcodeAtteEntrSys',
    name: 'QrcodeAtteEntrSys',
    component: () => import('@/views/QrcodeAtteEntrSys/index.vue')
  },
  // 查询--详情页面
  {
    path: '/commHrQueryPage',
    name: 'commHrQueryPage',
    component: () => import('@/views/commHrQueryPage/index.vue')
  },
  // 我的--人脸采集
  {
    path: '/faceCollection',
    name: 'faceCollection',
    component: () => import('@/views/faceCollection/index.vue')
  },
  // GS任务-下达任务/申请任务
  {
    path: '/ordTask',
    name: 'ordTask',
    component: () => import('@/views/GSTask/ordTask.vue')
  },
  // GS任务-任务审批
  {
    path: '/exaTask',
    name: 'exaTask',
    component: () => import('@/views/GSTask/exaTask.vue')
  },
  // GS任务-任务审批-详细
  {
    path: '/exaTaskApply',
    name: 'exaTaskApply',
    component: () => import('@/views/GSTask/exaTaskApply.vue')
  },
  // GS任务-任务提交
  {
    path: '/subTask',
    name: 'subTask',
    component: () => import('@/views/GSTask/subTask.vue')
  },
  // GS任务-任务提交-详细
  {
    path: '/subTaskApply',
    name: 'subTaskApply',
    component: () => import('@/views/GSTask/subTaskApply.vue')
  },
  // GS任务-任务评分
  {
    path: '/evaTask',
    name: 'evaTask',
    component: () => import('@/views/GSTask/evaTask.vue')
  },
  // GS任务-任务评分-详细
  {
    path: '/evaTaskApply',
    name: 'evaTaskApply',
    component: () => import('@/views/GSTask/evaTaskApply.vue')
  },
  // GS任务-任务查询
  {
    path: '/queTask',
    name: 'queTask',
    component: () => import('@/views/GSTask/queTask.vue')
  },
  // GS任务-任务查询-详细
  {
    path: '/queTaskApply',
    name: 'queTaskApply',
    component: () => import('@/views/GSTask/queTaskApply.vue')
  },
  // GS任务-评论
  {
    path: '/pfTask',
    name: 'pfTask',
    component: () => import('@/views/GSTask/pfTask.vue')
  },
  // GS任务-评论
  {
    path: '/pdfView',
    name: 'pdfView',
    component: () => import('@/views/pdfView/index.vue')
  },
  // 预入职外部跳转页面
  {
    path: '/preEntryOfferCheck',
    name: 'preEntryOfferCheck',
    component: () => import('@/views/preEntryOfferCheck'),
    // 不需要登录的时候，nologin设置为true
    meta: { nologin: true }
  },
  {
    path: '/trainPrjReqCheck',
    name: 'trainPrjReqCheck',
    component: () => import('@/views/trainPrjReqCheck'),
    // 不需要登录的时候，nologin设置为true
    meta: { nologin: true }
  },
  // 单点登录查看问卷调查
  {
    path: '/ssoCommPjDetail',
    name: 'ssoCommPjDetail',
    component: () => import('@/views/ssoCommPjDetail'),
    // 不需要登录的时候，nologin设置为true
    meta: { nologin: true }
  },
  // 查看问卷调查
  {
    path: '/queryCommPjDetail',
    name: 'queryCommPjDetail',
    component: () => import('@/views/ssoCommPjDetail/queryCommPjDetail.vue')
  },
  // 单点登录查看已完成试卷
  {
    path: '/ssoFinishPaper',
    name: 'ssoFinishPaper',
    component: () => import('@/views/ssoFinishPaper'),
    // 不需要登录的时候，nologin设置为true
    meta: { nologin: true }
  },
  // 查看已完成试卷
  {
    path: '/showFinishPaper',
    name: 'showFinishPaper',
    component: () => import('@/views/ssoFinishPaper/showFinishPaper.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  // base:process.env.BASE_URL,
  routes
})

export default router
